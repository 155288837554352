import React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { StaticImage } from 'gatsby-plugin-image'
import "./about.scss"

const AboutPage = () => {
  return (
  <Layout
    title="About | Guiding Digital"
    description="Located in Northwest Arkansas, Guiding Digital exists to help organizations establish their online presence and help developers through YouTube videos and online courses.">

    <HeroBanner
      headline="About Guiding Digital"
      className="aboutPage"
    />

    <Container className="about-guidingdigital">
        <Row>
          <Col xs={12} md={7}>
            <h2>Who Is Guiding Digital?</h2>
            <p>
              Guiding Digital was born out of my passion for web development and my passion for mentoring others.
              I've been involved in website development and digital marketing for over 22 years and 
              one of my most rewarding roles was lead programmer on a web development team.  I loved being able to come 
              along side developers and help them grow in their web development skills.
            </p>
            <p>
              I started Guiding Digital in 2020 with a mission to help organizations establish their online presence 
              and help developers through YouTube videos and online courses.
            </p>
          </Col>
          <Col className="my-auto text-center d-none d-md-block" md={5}>
            <StaticImage
              src="../../images/guiding-digital.png"
              alt="Guiding Digital"
              layout="fixed"
              width={300} />
          </Col>
        </Row>
        <Row>
          <Col className="profileImage my-auto d-none d-md-block" md={5}>
              <StaticImage
                src="../../images/philip-sparks.png"
                alt="Philip Sparks, owner"
                layout="constrained"
                width={500} />
          </Col>
          <Col xs={12} md={7} className="my-auto">
            <h2 className="alternate">Meet Philip</h2>

            <StaticImage
                src="../../images/philip-sparks-16x9.png"
                alt="Philip Sparks, owner"
                layout="constrained"
                width={700}
                className="d-block d-md-none mx-auto mobile-profile-image" />
            <p>
            I'm the owner and founder of Guiding Digital and a full stack web developer that has been involved with developing websites in the United States for over 22 
            years.  I spent the first 20 years of my career working for a fortune 100 company working as a web developer 
            and web architect.  I've worked with a wide range of web development technologies including Microsoft.Net, 
            Sitecore CMS, Drupal, Gatsby, WordPress, and AWS and Azure cloud computing.</p>
            <p>
              Today, I am a freelance web development expert who helps organizations establish their online presence and 
              helps developers through YouTube and online courses such as Udemy and Skill Share.
            </p>
          </Col>
        </Row>
        
      </Container>
{/* 
      <Container className="alternateSection">
        <h5>Our Mission</h5>
        <p>
          To support web developers in their passionate pursuit of building great websites, and to support
          small business with their online marketing needs.
        </p>
      </Container>
        
      <Container>
        <Row>
            <Col xs={12}>
              <h5>Our Core Values</h5>
            </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h5 class="text-center">God Honoring</h5>
            <p>
                My faith is important to me.  Guiding Digital will strive to be a company that seeks
                to glorify God in all we do.
            </p>
          </Col>
          <Col md={4}>
            <h5 class="text-center">Generosity</h5>
            <p>
              Guding Digital will strive to be a company that is generous with our time and resources to 
              those that are in need.
            </p>
          </Col>
          <Col md={4}>
            <h5 class="text-center">Respect</h5>
            <p>
              Guiding Digital will strive to be a company that is respectful to all in our words and 
              actions.  We are respectful in our in-person and online dialogues even to those 
              we disagree with.
            </p>
          </Col>
          <Col md={4}>
            <h5 class="text-center">Integrity</h5>
            <p>
              Guiding Digital will strive to be a company that prioritizes doing the right thing 
              over profit and to be a company that does what we say we’re going to do.
            </p>
          </Col>
          <Col md={4}>
            <h5 class="text-center">Servant Leadership</h5>
            <p>
              Guiding Digital will strive to lead others with a mentality of a servant leader.
            </p>
          </Col>
        </Row>
    </Container>

    */}
  </Layout>
  )
}

export default AboutPage